
interface ObjectLiteral {
  [key: string]: string
}
export const commentReportStatusConstant: ObjectLiteral = {
  '0': 'Pending',
  '1': 'In Process',
  '2': 'Processed',
  '3': 'Reject',
}

export const commentReportTypesConstant: ObjectLiteral = {
  '1': 'Spam',
  '2': 'Spoil',
}


export const mangaStatusConstant: ObjectLiteral = {
  '1': 'Done',
  '0': 'Ongoing',
}


export const userStatusConstant: ObjectLiteral = {
  '1': 'Active',
  '-1': 'Banned',
}

import {PaginationData} from "./_models";

const SIZE = 5;

const processPagingData = (currentPage: number, limit: number, count: number) : PaginationData => {
    const maxPage = Math.ceil(count / limit);
    let start = currentPage - Math.floor((SIZE - 1) / 2);
    let end = currentPage + Math.ceil((SIZE - 1) / 2);

    start = (end > maxPage) ? start - (end - maxPage) : start;
    end = (start < 1) ? end + (1 - start) : end;
    const links = [];

    if (maxPage > SIZE && start > 1){
        links.push({
            page: 1,
            label: '&laquo;',
        });
    };
    for (let i = start; i <= end; i++) {
        if (i > 0 && i <= maxPage) {
            links.push({
                page: i,
                label: i.toString(),
            });
        }
    };
    if (maxPage > SIZE && end < maxPage){
        links.push({
            page: maxPage,
            label: '&raquo;',
        });
    };
    return {
        page: currentPage,
        items_per_page: limit,
        links: links,
    };
}

export {processPagingData}

import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {CommentReportsListLoading} from '../components/loading/CommentReportsListLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CommentReport} from '../../core/_models'
import {useAuth} from '../../../../auth'
import {commentReportStatusConstant} from '../../../../../common/data.config'
import Dropdown from 'react-bootstrap/Dropdown'
import {updateCommentReport} from '../core/_requests'

type Props = {
  isUserLoading: boolean
  commentReport: any
}

const editUserSchema = Yup.object().shape({
  admin_id: Yup.number(),
  admin_comment: Yup.string().min(3, 'Minimum 3 symbols').max(200, 'Maximum 200 symbols'),
  status: Yup.number().required('Status is required'),
})

const ComemntReportEditModalForm: FC<Props> = ({commentReport, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const {currentUser, logout} = useAuth()
  const [commentReportStatus, setCommentReportStatus] = useState(commentReport.status)

  if (!currentUser) {
    logout()
    // document.location.reload()
  }

  const [commentReportForEdit] = useState<CommentReport>({
    ...commentReport,
    id: commentReport.id || undefined,
    admin_id: currentUser?.id || '',
    admin_comment: commentReport.admin_comment || '',
    status: commentReport.status || 1,
  })

  const cancel = (withRefresh?: boolean) => {
    // console.log(formik.errors)
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  // const {roles} = useQueryData();
  const formik = useFormik({
    initialValues: commentReportForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        let updateCommentReportData: any
        let updateCommentReportResult: any
        if (isNotEmpty(values.id)) {
          updateCommentReportData = {
            id: values.id,
            admin_id: currentUser?.id,
            admin_comment: values.admin_comment,
            status: commentReportStatus || commentReport.status,
          }
          updateCommentReportResult = await updateCommentReport(updateCommentReportData)

          if (updateCommentReportResult.success) {
            cancel(true)
          } else {
            setStatus(updateCommentReportResult.message)
            console.log('error create chapter', updateCommentReportResult)
          }
        } else {
          cancel(false)
        }
      } catch (ex) {
        setStatus('Something went wrong!')
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Admin comment</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Enter comment'
              rows={10}
              {...formik.getFieldProps('admin_comment')}
              name='admin_comment'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.admin_comment && formik.errors.admin_comment},
                {
                  'is-valid': formik.touched.admin_comment && !formik.errors.admin_comment,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.admin_comment && formik.errors.admin_comment && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.admin_comment}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
          {/* begin::Input group */}
          <div className='form-group mb-5'>
            <label className='required fw-bold fs-6 mb-2' htmlFor={'status'}>
              Select status:
            </label>
            <Dropdown>
              <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
                {commentReportStatusConstant[commentReportStatus]}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.entries(commentReportStatusConstant).map(([key, value]) => (
                  <Dropdown.Item
                    onClick={() => {
                      setCommentReportStatus(key)
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {formik.touched.status && formik.errors.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.status}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          <div className='separator separator-dashed my-6'></div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <CommentReportsListLoading />}
    </>
  )
}

export {ComemntReportEditModalForm}

import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryDataProvider, QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/ActivityLogssListHeader'
import {UsersTable} from './table/ActivityLogsTable'
import {KTCard} from '../../../../../_metronic/helpers'

const UsersList = () => {
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable />
      </KTCard>
    </>
  )
}

const ActivityLogListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryDataProvider>
        <ListViewProvider>
          <UsersList />
        </ListViewProvider>
      </QueryDataProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ActivityLogListWrapper}

import axios from 'axios'
import {QueryState} from '../../../../../../_metronic/helpers'
import {AdminsQueryResponse} from './_models'
import qs from 'qs'
import {AdminModel} from '../../core/_models'
import {ApiResponse} from '../../../../common/core/_models'
import {activityLogUrls} from '../../../../common/core/ApiURLData'
import {processApiGetListAdminsResponse} from '../../core/Helpers'
import {processPagingData} from '../../../../common/core/PagingHelpers'

const getListActivityLog = async (state: QueryState): Promise<AdminsQueryResponse> => {
  const {sort = 'id', order = 'desc', filter = {}} = state
  const limit = 20
  const page = state.page
  let uri = qs.stringify(
    {
      limit,
      page,
      keyword: state.search && state.search.trim().length > 0 ? state.search.trim() : null,
      orderBy: `${sort}:${order}`,
      startDate: (filter as any)?.startDate || null,
      endDate: (filter as any)?.endDate || null,
    },
    {
      skipNulls: true,
    }
  )
  const apiResponse = await axios
    .get<ApiResponse<{rows: AdminModel[]; count: number}>>(`${activityLogUrls.GET_LIST}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  const apiResult = processApiGetListAdminsResponse(apiResponse)
  let rs = {
    data: apiResult.success && apiResult.result?.rows ? apiResult.result.rows : [],
    payload: {
      message: apiResult.message,
      pagination:
        apiResult.success && apiResult.result?.rows
          ? processPagingData(page, limit, apiResult.result?.count)
          : {},
    },
  } as AdminsQueryResponse
  return rs
}

export {getListActivityLog}

import {FC} from 'react'
import {CommentModel} from '../../../core/_models'

type Props = {
  comment?: CommentModel
}

const IsReplyCell: FC<Props> = ({comment}) => {
  return comment?.parent_id ? (
    <div className='badge badge-primary fw-bolder'>Yes</div>
  ) : (
    <div className='badge badge-light fw-bolder'>No</div>
  )
}

export {IsReplyCell}

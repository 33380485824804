/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {commentReportTypesConstant} from '../../../../../../common/data.config'

type Props = {
  data: {
    type: keyof typeof commentReportTypesConstant
  }
}

const CommentReportTypeCell: FC<Props> = ({data}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{commentReportTypesConstant[data.type]}</span>
      </div>
    </div>
  )
}

export {CommentReportTypeCell}

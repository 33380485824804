import axios from 'axios'
import {AuthTokens} from './_models'
import {ApiResponse, ApiResult} from '../../common/core/_models'
import {processApiLoginResponse, processApiGetUserInfoResponse} from './AuthHelpers'
import {AdminModel} from "../../admin-management/admin/core/_models";

import {adminUrls} from '../../common/core/ApiURLData';
import {authUrls} from '../../common/core/ApiURLData';

// Server should return AuthModel
export async function login(email: string, password: string): Promise<ApiResult<{ admin: AdminModel, tokens: AuthTokens }>> {
    const apiResponse = await axios.post<ApiResponse<{ admin: AdminModel, tokens: AuthTokens }>>(authUrls.LOGIN_URL, {
        email,
        password,
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiLoginResponse(apiResponse);
}

export async function getUserByToken(token: string) : Promise<ApiResult<{admin: AdminModel}>> {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    }
    const apiResponse = await axios.get<ApiResponse<{admin: AdminModel}>>(adminUrls.GET_USER_BY_ACCESS_TOKEN_URL, config).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiGetUserInfoResponse(apiResponse);
}

export async function refreshToken(refreshToken: string) : Promise<ApiResult<{tokens: AuthTokens}>> {
    const apiResponse = await axios.post<ApiResponse<{ tokens: AuthTokens }>>(authUrls.REFRESH_TOKEN_URL, {
        refreshToken,
    }, {
        headers: {
            tried: 1,
        }
    }).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiLoginResponse(apiResponse)
}

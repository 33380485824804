import {useListView} from '../../core/ListViewProvider'
import {CommentsListGrouping} from './CommentsListGrouping'
import {CommentsListSearchComponent} from './CommentsListSearchComponent'

const CommentsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CommentsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/*  /!* begin::Group actions *!/*/}
        {selected.length > 0 ? <CommentsListGrouping /> : <></>}
        {/*  /!* end::Group actions *!/*/}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CommentsListHeader}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {commentReportStatusConstant} from '../../../../../../common/data.config'

type Props = {
  data: {
    status: keyof typeof commentReportStatusConstant
  }
}

const CommentReportStatusCell: FC<Props> = ({data}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{commentReportStatusConstant[data.status]}</span>
      </div>
    </div>
  )
}

export {CommentReportStatusCell}

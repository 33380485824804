const pick = (attributes : string[], object: any): any => {
    let data : any = {};
    for (const attr in object){
        if (attributes.includes(attr) && typeof object[attr] !== 'undefined'){
            data[attr] = object[attr];
        }
    }
    return data;
}

export {
    pick,
}

import React, {FC, useState} from 'react'
import {ID, KTIcon} from '../../../../../_metronic/helpers'

type Props = {
    setItemIdForUpdate: React.Dispatch<React.SetStateAction<ID>>;
    deleteFunction: () => void
}

const ConfirmDelete: FC<Props> = ({setItemIdForUpdate, deleteFunction}) => {
    const cancel = () => {
        setItemIdForUpdate(undefined)
    }
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const handleDelete = async () => {
        if (!isDeleting){
            setIsDeleting(true);
            await deleteFunction();
            setIsDeleting(false);
            setItemIdForUpdate(undefined);
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-400px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>Are you sure?</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => cancel()}
                                style={{cursor: 'pointer'}}
                            >
                                <KTIcon iconName='cross' className='fs-1'/>
                            </div>
                        </div>

                        <div className='modal-body scroll-y '>
                            <div className='text-center'>
                                <button
                                    type='reset'
                                    onClick={() => cancel()}
                                    className='btn btn-light me-3'
                                    data-kt-users-modal-action='cancel'
                                >
                                    Cancel
                                </button>
                                <button onClick={handleDelete} className='btn btn-danger'>
                                    {!isDeleting && 'Delete'}
                                    {isDeleting && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                Deleting...{' '}
                                            <span
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                                    )}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>

    )
}

export {ConfirmDelete}

const API_URL = process.env.REACT_APP_API_URL
const API_CRAWL_SERVICE_URL = process.env.REACT_APP_API_CRAWL_SERVICE_URL
const API_KEY = process.env.REACT_APP_API_KEY

const adminUrls = {
  GET_USER_BY_ACCESS_TOKEN_URL: `${API_URL}/module_user/v1/user/get_info?key=${API_KEY}`,
  GET_LIST: `${API_URL}/module_admin/v1/admins/get_list?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_admin/v1/admins/get_by_id?key=${API_KEY}`,
  ADD_ADMIN: `${API_URL}/module_admin/v1/admins/create?key=${API_KEY}`,
  UPDATE_ADMIN: `${API_URL}/module_admin/v1/admins/update?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_admin/v1/admins/delete_by_id?key=${API_KEY}`,
}

const activityLogUrls = {
  GET_LIST: `${API_URL}/module_admin/v1/activity_logs/get_list?key=${API_KEY}`,
}

const userUrls = {
  GET_LIST: `${API_URL}/module_user/v1/user/list?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_user/v1/user/get_user_info?key=${API_KEY}`,
  UPDATE: `${API_URL}/module_user/v1/user/update_info?key=${API_KEY}`,
  CREATE: `${API_URL}/module_user/v1/user/create?key=${API_KEY}`,
  UPDATE_AVATAR: `${API_URL}/module_user/v1/user/update-avatar?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_user/v1/user/delete_by_id?key=${API_KEY}`,
  COUNT_RECENT_NEW: `${API_URL}/module_user/v1/user/count_recent_new_users?key=${API_KEY}`,
}

const genreUrls = {
  GET_ALL: `${API_URL}/module_content/v1/genres/get_all?key=${API_KEY}`,
  GET_LIST: `${API_URL}/module_content/v1/genres/get_list?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_content/v1/genres/get_by_id?key=${API_KEY}`,
  CREATE: `${API_URL}/module_content/v1/genres/create?key=${API_KEY}`,
  DELETE: `${API_URL}/module_content/v1/genres/delete?key=${API_KEY}`,
  UPDATE_GENRE: `${API_URL}/module_content/v1/genres/update?key=${API_KEY}`,
}

const commentUrls = {
  GET_LIST: `${API_URL}/module_content/v1/comments/get_list?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_content/v1/comments/get_by_id?key=${API_KEY}`,
  UPDATE: `${API_URL}/module_content/v1/comments/update?key=${API_KEY}`,
  DELETE: `${API_URL}/module_content/v1/comments/delete_by_id?key=${API_KEY}`,
}

const mangaUrls = {
  GET_LIST: `${API_URL}/module_content/v1/mangas/get_list?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_content/v1/mangas/get_by_id?key=${API_KEY}`,
  CREATE_GENRE: `${API_URL}/module_content/v1/mangas/create?key=${API_KEY}`,
  UPDATE_GENRE: `${API_URL}/module_content/v1/mangas/update?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_content/v1/mangas/delete_by_id?key=${API_KEY}`,
  COUNT_RECENT_UPDATE: `${API_URL}/module_content/v1/mangas/count_recent_updated_manga?key=${API_KEY}`,
  CRAWL_POSTER_IMAGE: `${API_CRAWL_SERVICE_URL}/mangas/:mangaId/re-crawl-poster-image`,
}
const chapterUrls = {
  GET_BY_ID: `${API_URL}/module_content/v1/chapters/get_by_id?key=${API_KEY}`,
  CREATE: `${API_URL}/module_content/v1/chapters/create?key=${API_KEY}`,
  UPDATE: `${API_URL}/module_content/v1/chapters/update?key=${API_KEY}`,
  GET_LIST: `${API_URL}/module_content/v1/chapters/get_list?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_content/v1/chapters/delete_by_id?key=${API_KEY}`,
}

const commentReportUrls = {
  GET_BY_ID: `${API_URL}/module_report/v1/comment-reports/get_by_id?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_report/v1/comment-reports/delete_by_id?key=${API_KEY}`,
  UPDATE: `${API_URL}/module_report/v1/comment-reports/update?key=${API_KEY}`,
  GET_LIST: `${API_URL}/module_report/v1/comment-reports/get_list?key=${API_KEY}`,
}

const authUrls = {
  LOGIN_URL: `${API_URL}/module_auth/v1/auth/login?key=${API_KEY}`,
  REFRESH_TOKEN_URL: `${API_URL}/module_auth/v1/auth/refresh_tokens?key=${API_KEY}`,
}

const permissionUrls = {
  GET_LIST: `${API_URL}/module_admin/v1/permissions/get_list?key=${API_KEY}`,
  GET_ALL: `${API_URL}/module_admin/v1/permissions/get_all?key=${API_KEY}`,
}

const roleUrls = {
  GET_LIST: `${API_URL}/module_admin/v1/roles/get_list?key=${API_KEY}`,
  GET_ALL: `${API_URL}/module_admin/v1/roles/get_all?key=${API_KEY}`,
  GET_BY_ID: `${API_URL}/module_admin/v1/roles/get_by_id?key=${API_KEY}`,
  ADD_ROLE: `${API_URL}/module_admin/v1/roles/create?key=${API_KEY}`,
  UPDATE_ROLE: `${API_URL}/module_admin/v1/roles/update?key=${API_KEY}`,
  DELETE_BY_ID: `${API_URL}/module_admin/v1/roles/delete_by_id?key=${API_KEY}`,
}

const settingUrls = {
  GET_CRAWLER_SETTING: `${API_URL}/module_setting/v1/settings/get-setting-for-crawler?key=${API_KEY}`,
  UPDATE_CRAWLER_SETTING: `${API_URL}/module_setting/v1/settings/update-setting-for-crawler?key=${API_KEY}`,

  GET_CONTACTS_SETTING: `${API_URL}/module_setting/v1/settings/get-setting-for-contacts?key=${API_KEY}`,
  UPDATE_CONTACTS_SETTING: `${API_URL}/module_setting/v1/settings/update-setting-for-contacts?key=${API_KEY}`,

  GET_SEO_SETTING: (page: string) =>
    `${API_URL}/module_setting/v1/settings/get-setting-for-seo/${page}?key=${API_KEY}`,
  UPDATE_SEO_SETTING: (page: string) =>
    `${API_URL}/module_setting/v1/settings/update-setting-for-seo/${page}?key=${API_KEY}`,
}

const cacheUrls = {
  GET_LIST_CACHE: `${API_URL}/module_cache/v1/caches/get_list?key=${API_KEY}`,
  CLEAR_CACHE: (cacheId: string) =>
    `${API_URL}/module_cache/v1/caches/clear_cache/${cacheId}?key=${API_KEY}`,
}

export {
  authUrls,
  adminUrls,
  permissionUrls,
  roleUrls,
  genreUrls,
  mangaUrls,
  chapterUrls,
  settingUrls,
  cacheUrls,
  commentReportUrls,
  userUrls,
  commentUrls,
  activityLogUrls,
}

import {ApiResponse, ApiResult} from "../../../../common/core/_models";
import {Role} from "../../core/_models";
import * as _ from "lodash";
import {Permission} from "../../../permission/core/_models";
import {PermissionGroup} from "../../../permission/permissions-list/core/_models";

const processApiGetAllRolesResponse = (data: ApiResponse<Role[]>): ApiResult<Role[]> => {
    let result = {
        success: false,
        message: 'Opp. Something went wrong!',
    };
    return {...result, ...data};
}

const processApiGetRoleByIDResponse = (data: ApiResponse<Role>): ApiResult<Role> => {
    let result = {
        success: false,
        message: 'Opp. Something went wrong!',
    };
    return {...result, ...data};
}

const processModelUpdateRoleData = (permissions: Permission[]): PermissionGroup[] => {
    let permissionGroups : PermissionGroup[]  = [];
    permissions.forEach((permission) => {
        const partials = permission.key.split('.');
        if (partials. length > 0){
            const key = partials[0];
            let action: string = '';
            if (partials[1] === '*'){
                 action = 'All';
            }else{
                 action = partials[1].replace('_', ' ');
            }
            const index = _.findLastIndex(permissionGroups, (item: PermissionGroup)=>{
                return item.modelKey === key;
            })

            if (index >= 0){
                permissionGroups[index].actions.push({
                    id: permission.id,
                    name: action,
                })
            }else {
                let modelName: string = '';
                if (key === '*'){
                    modelName = 'All Permission';
                }else{
                    modelName = key.replace('_', ' ');
                }
                permissionGroups.push({
                    modelKey: key,
                    modelName: modelName,
                    actions: [{
                        id: permission.id,
                        name: action,
                    }],
                });
            }
        }
    });
    return _.orderBy(permissionGroups, ['modelKey'], ['asc']);
}

export {processApiGetAllRolesResponse, processModelUpdateRoleData, processApiGetRoleByIDResponse}

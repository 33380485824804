import {CommentReport} from './_models'
import {ApiResponse, ApiResult} from '../../../common/core/_models'

const processApiGetListCommentReportsResponse = (
  data: ApiResponse<{rows: CommentReport[]; count: number}>
): ApiResult<{rows: CommentReport[]; count: number}> => {
  let result = {
    success: false,
    message: 'Opp. Something went wrong!',
  }
  return {...result, ...data}
}

const processApiGetCommentReportByIdResponse = (
  data: ApiResponse<CommentReport>
): ApiResult<CommentReport> => {
  let result = {
    success: false,
    message: 'Opp. Something went wrong!',
  }
  return {...result, ...data}
}

export {processApiGetListCommentReportsResponse, processApiGetCommentReportByIdResponse}

import axios from 'axios'
import {ID, QueryState} from '../../../../../../_metronic/helpers'
import {CommentsQueryResponse} from './_models'
import qs from 'qs'
import {CommentModel, Genre} from '../../core/_models'
import {pick} from '../../../../common/helpers/pick'
import {ApiResponse, ApiResult} from '../../../../common/core/_models'
import {commentUrls, genreUrls} from '../../../../common/core/ApiURLData'
import {processApiGetGenreByIdResponse, processApiGetListGenresResponse} from '../../core/Helpers'
import {processPagingData} from '../../../../common/core/PagingHelpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`

const getListComment = async (state: QueryState): Promise<CommentsQueryResponse> => {
  const {sort = 'id', order = 'desc'} = state
  const limit = 20
  const page = state.page
  let uri = qs.stringify(
    {
      limit,
      page,
      keyword: state.search && state.search.trim().length > 0 ? state.search.trim() : null,
      chapter_id: null,
      manga_id: null,
      orderBy: `${sort}:${order}`,
    },
    {
      skipNulls: true,
    }
  )
  const apiResponse = await axios
    .get<ApiResponse<{rows: Genre[]; count: number}>>(`${commentUrls.GET_LIST}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  const apiResult = processApiGetListGenresResponse(apiResponse)
  let rs = {
    data: apiResult.success && apiResult.result?.rows ? apiResult.result.rows : [],
    payload: {
      message: apiResult.message,
      pagination:
        apiResult.success && apiResult.result?.rows
          ? processPagingData(page, limit, apiResult.result?.count)
          : {},
    },
  } as CommentsQueryResponse
  return rs
}

const updateComment = async (comment: CommentModel): Promise<ApiResult<CommentModel>> => {
  const dataSend = pick(['id', 'is_spam', 'is_spoil'], comment)
  const apiResponse = await axios
    .put(`${commentUrls.UPDATE}`, dataSend, {})
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  return processApiGetGenreByIdResponse(apiResponse)
}

const getCommentById = async (id: ID): Promise<Genre> => {
  let uri = qs.stringify(
    {
      id,
      'includes[]': ['user', 'manga', 'chapter'],
    },
    {
      skipNulls: true,
    }
  )
  const apiResponse = await axios
    .get(`${commentUrls.GET_BY_ID}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  const apiResult = processApiGetGenreByIdResponse(apiResponse)
  return apiResult.success && apiResult.result ? apiResult.result : {}
}

const deleteComment = async (commentId: ID): Promise<any> => {
  const apiResponse = await axios
    .delete(`${commentUrls.DELETE}`, {data: {id: commentId}})
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  return apiResponse
}

const deleteByIds = async (ids: any): Promise<any> => {
  const requests = ids.map((id: any) => axios.delete(`${commentUrls.DELETE}`, {data: {id: id}}))
  return axios.all(requests).then(() => {
    return true
  })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  deleteSelectedUsers,
  getListComment,
  getCommentById,
  updateComment,
  deleteComment,
  deleteByIds,
}

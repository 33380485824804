import axios from 'axios'
import {QueryState, Response} from '../../../../../../_metronic/helpers'
import {CommentReportQueryResponse} from './_models'
import qs from 'qs'
import {CommentReport} from '../../core/_models'
import {ApiResponse} from '../../../../common/core/_models'
import {commentReportUrls} from '../../../../common/core/ApiURLData'
import {
  processApiGetCommentReportByIdResponse,
  processApiGetListCommentReportsResponse,
} from '../../core/Helpers'
import {processPagingData} from '../../../../common/core/PagingHelpers'

const getListCommentReport = async (state: QueryState): Promise<CommentReportQueryResponse> => {
  const {sort = 'id', order = 'desc', filter = {}} = state
  const limit = 20
  const page = state.page
  let uri = qs.stringify(
    {
      limit,
      page,
      status: (filter as any)?.status || null,
      orderBy: `${sort}:${order}`,
      type: (filter as any)?.type || null,
    },
    {
      skipNulls: true,
    }
  )
  const apiResponse = await axios
    .get<ApiResponse<{rows: CommentReport[]; count: number}>>(
      `${commentReportUrls.GET_LIST}&${uri}`
    )
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  const apiResult = processApiGetListCommentReportsResponse(apiResponse)
  let rs = {
    data: apiResult.success && apiResult.result?.rows ? apiResult.result.rows : [],
    payload: {
      message: apiResult.message,
      pagination:
        apiResult.success && apiResult.result?.rows
          ? processPagingData(page, limit, apiResult.result?.count)
          : {},
    },
  } as CommentReportQueryResponse
  return rs
}

const deleteById = async (id: any): Promise<any> => {
  const apiResponse = await axios
    .delete(`${commentReportUrls.DELETE_BY_ID}`, {data: {id}} as any)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  return apiResponse
}

const deleteByIds = async (ids: any): Promise<any> => {
  const requests = ids.map((id: any) =>
    axios.delete(`${commentReportUrls.DELETE_BY_ID}`, {data: {id}} as any)
  )
  return axios.all(requests).then(() => {})
}

const getCommentReportByID = async (id: any): Promise<CommentReport> => {
  let uri = qs.stringify(
    {
      id,
    },
    {
      skipNulls: true,
    }
  )
  const apiResponse = await axios
    .get(`${commentReportUrls.GET_BY_ID}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  const apiResult = processApiGetCommentReportByIdResponse(apiResponse)
  return (apiResult.success && apiResult.result ? apiResult.result : {}) as CommentReport
}

const updateCommentReport = (data: any): Promise<CommentReport | undefined> => {
  return axios
    .put(`${commentReportUrls.UPDATE}`, data)
    .then((response: Response<CommentReport>) => response.data)
}

export {deleteById, getListCommentReport, getCommentReportByID, deleteByIds, updateCommentReport}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  data: any
}

const CommentReportAdminCell: FC<Props> = ({data}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{data.Admin?.name}</span>
      </div>
    </div>
  )
}

export {CommentReportAdminCell}

import {CommentReportsListFilter} from './CommentReportsListFilter'

const CommentReportListToolbar = () => {
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <CommentReportsListFilter />
    </div>
  )
}

export {CommentReportListToolbar}

import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { CommentsListWrapper } from './comment-lists/CommentsList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Comments Management',
    path: '/cms/content/comment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const GenresPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Comments list</PageTitle>
              <CommentsListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default GenresPage

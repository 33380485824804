import {useListView} from '../../core/ListViewProvider'
import { ActivityLogsListFilter } from './ActivityLogsListFilter'
import {ActivityLogsListSearchComponent} from './ActivityLogsListSearchComponent'

const UsersListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <ActivityLogsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/*{selected.length > 0 ? <MangasListGrouping /> : <UsersListToolbar />}*/}
        <ActivityLogsListFilter/>
        {/* <UsersListToolbar /> */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}

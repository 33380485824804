/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo, createContext, Dispatch, SetStateAction} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext, ID, initialListView,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import {getListActivityLog} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {AdminModel} from "../../core/_models";
import {Role} from "../../../role/core/_models";
import {getAllRole} from "../../../role/roles-list/core/_requests";

const QueryDataContext = createContext<{roles: Role[]
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>}>({
  roles: [],
  setItemIdForDelete: () => {},
});

const QueryDataProvider: FC<WithChildren> = ({children}) => {
  const [data, setData] = useState<{roles: Role[]}>({roles: []});
  const [itemIdForDelete, setItemIdForDelete] = useState<ID>(initialListView.itemIdForUpdate);

  useEffect( () => {
    const promises = [];
    promises.push(getAllRole());
    Promise.all(promises).then((dataQR) => {
      const [dataRoles] = dataQR as [Role[]];
      setData({roles: dataRoles});
    });
  }, [])


  return (
      <QueryDataContext.Provider value={{roles: data.roles, itemIdForDelete, setItemIdForDelete}}>
        {children}
      </QueryDataContext.Provider>
  )
}

const useQueryData = () => useContext(QueryDataContext);

const QueryResponseContext = createResponseContext<AdminModel>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `activity-log-list-${query}`,
    () => {
      return getListActivityLog(state)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryData,
  QueryDataProvider,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}

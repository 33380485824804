/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {CommentModel} from '../../../core/_models'

type Props = {
  comment: CommentModel
}

const CommentmangaCell: FC<Props> = ({comment}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{comment?.manga?.title || 'null'}</span>
    </div>
  </div>
)

export {CommentmangaCell}

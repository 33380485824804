// @ts-nocheck
import {Column} from 'react-table'
import {CommentInfoCell} from './CommentInfoCell'
import {CommentActionsCell} from './CommentActionsCell'
import {CommentSelectionCell} from './CommentSelectionCell'
import {CommentCustomHeader} from './CommentCustomHeader'
import {CommentSelectionHeader} from './CommentSelectionHeader'
import {AdminModel} from '../../../core/_models'
import {CommentmangaCell} from './CommentMangaCell'
import {CommentContentCell} from './CommentContentCell'
import {TimeCell} from '../../../../../common/components/list/columns/TimeCell'
import {IsReplyCell} from './IsHotCell'
import { CommentChapterCell } from './CommentChapterCell'

const usersColumns: ReadonlyArray<Column<AdminModel>> = [
  {
    Header: (props) => <CommentSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CommentSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CommentCustomHeader tableProps={props} title='ID' className='min-w-50px' />,
    accessor: 'id',
  },
  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='User' className='min-w-125px' />
    ),
    id: 'user:name',
    Cell: ({...props}) => <CommentInfoCell comment={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Manga' className='min-w-125px' />
    ),
    id: 'manga:title',
    Cell: ({...props}) => <CommentmangaCell comment={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Chapter' className='min-w-125px' />
    ),
    id: 'chapter:name',
    Cell: ({...props}) => <CommentChapterCell comment={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Content' className='min-w-25px ' />
    ),
    id: 'content',
    Cell: ({...props}) => <CommentContentCell comment={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Created At' className='min-w-75px' />
    ),
    id: 'created_at',
    accessor: 'created_at',
    Cell: ({...props}) => <TimeCell time={props.data[props.row.index].created_at} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Reply' className='min-w-25px ' />
    ),
    id: 'parent_id',
    Cell: ({...props}) => <IsReplyCell comment={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CommentActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}

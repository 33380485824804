import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {ActivityLogListWrapper} from './activities-list/ActivityLogList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Activity log',
    path: '/cms/admin-management/activity-log',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ActivityLogPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Activity log</PageTitle>
              <ActivityLogListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ActivityLogPage

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {CommentsListHeader} from './components/header/CommentsListHeader'
import {CommentsTable} from './table/CommentsTable'
import {CommentEditModal} from './comment-edit-modal/CommentEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {ConfirmDelete} from '../../../common/components/modals/ConfirmDelete'
import {deleteComment} from './core/_requests'

const CommentsList = () => {
  const {itemIdForUpdate, itemIdForDelete, setItemIdForDelete} = useListView()
  const {refetch} = useQueryResponse()
  const deleteItem = async () => {
    if (itemIdForDelete) {
      const deleted = await deleteComment(itemIdForDelete)
      if (deleted) {
        refetch()
      }
    }
  }

  return (
    <>
      <KTCard>
        <CommentsListHeader />
        <CommentsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CommentEditModal />}
      {itemIdForDelete !== undefined && (
        <ConfirmDelete setItemIdForUpdate={setItemIdForDelete} deleteFunction={deleteItem} />
      )}
    </>
  )
}

const CommentsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CommentsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CommentsListWrapper}

import {isNotEmpty, KTIcon} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

const CommentEditModalHeader = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const isUpdate: boolean = isNotEmpty(itemIdForUpdate)

  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{isUpdate ? 'Update Comment' : 'Add Genre'}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {CommentEditModalHeader}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {CommentModel} from '../../../core/_models'

type Props = {
  comment: CommentModel
}

const CommentChapterCell: FC<Props> = ({comment}) => {
  return !comment?.chapter ? (
    <div className='badge badge-light fw-bolder'>Null</div>
  ) : (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{comment.chapter.name}</span>
      </div>
    </div>
  )
}

export {CommentChapterCell}

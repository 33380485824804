import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {CommentModel} from '../../core/_models'
import {ApiResult} from '../../../../common/core/_models'
import clsx from 'clsx'
import {updateComment} from '../core/_requests'

type Props = {
  isUserLoading: boolean
  comment: CommentModel
}

const editUserSchema = Yup.object().shape({
  is_spam: Yup.number().required('is_spam is required'),
  is_spoil: Yup.number().required('is_spoil is required'),
})

const CommentEditModalForm: FC<Props> = ({comment, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [customAttrs, setCustomAttrs] = useState({
    is_spam: isNotEmpty(comment.is_spam) ? !!comment.is_spam : false,
    is_spoil: isNotEmpty(comment.is_spoil) ? !!comment.is_spoil : false,
  })

  const [commentForEdit] = useState<CommentModel>({
    ...comment,
    is_spam: comment.is_spam || 0,
    is_spoil: comment.is_spoil || 0,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  // const {roles} = useQueryData();
  const formik = useFormik({
    initialValues: commentForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      try {
        let submitGenreResult: ApiResult<CommentModel>

        if (isNotEmpty(values.id)) {
          submitGenreResult = await updateComment({
            id: values.id,
            is_spam: customAttrs.is_spam ? 1 : 0,
            is_spoil: customAttrs.is_spoil ? 1 : 0,
          })
          if (submitGenreResult.success) {
            setSubmitting(false)
            cancel(true)
          } else {
            setStatus(submitGenreResult.message)
            setSubmitting(false)
          }
        } else {
        }
      } catch (ex) {
        setStatus('Something went wrong!')
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Content</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              disabled
              placeholder=''
              value={commentForEdit.content}
              type='text'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Manga</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              disabled
              placeholder=''
              value={commentForEdit?.manga?.title || 'null'}
              type='text'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Chapter</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              disabled
              placeholder=''
              value={commentForEdit?.chapter?.name || 'null'}
              type='text'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Created At</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              disabled
              placeholder=''
              value={commentForEdit?.created_at || 'null'}
              type='text'
              name='name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              // disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          <div className='container'>
            <div className='row'>
              <div className='col-6 '>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='fw-bold fs-6 mb-2'>Like</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    disabled
                    placeholder=''
                    value={commentForEdit?.total_like || 0}
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    // disabled={formik.isSubmitting || isUserLoading}
                  />
                  {/* end::Input */}
                </div>
                {/* end::Input group */}
              </div>
              <div className='col-6 '>
                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='fw-bold fs-6 mb-2'>Dislike</label>
                  {/* end::Label */}

                  {/* begin::Input */}
                  <input
                    disabled
                    placeholder=''
                    value={commentForEdit?.total_dislile || 0}
                    type='text'
                    name='name'
                    className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                    autoComplete='off'
                    // disabled={formik.isSubmitting || isUserLoading}
                  />
                  {/* end::Input */}
                </div>
                {/* end::Input group */}
              </div>
            </div>
          </div>

          <div className='separator separator-dashed my-6'></div>
          <div className='form-group d-flex flex-stack'>
            <div className='d-flex flex-column'>
              <label className='required fw-bold fs-6 mb-2'>Is Spam</label>
            </div>
            <div className='d-flex justify-content-end'>
              <div className='form-check form-check-custom form-check-solid form-check-success form-switch'>
                <div
                  className='form-check form-check-custom form-check-solid form-switch
                    mb-2'
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='is_hot'
                    checked={customAttrs.is_spam}
                    onChange={() => {
                      setCustomAttrs({...customAttrs, is_spam: !customAttrs.is_spam})
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='separator separator-dashed my-6'></div>
          <div className='form-group d-flex flex-stack'>
            <div className='d-flex flex-column'>
              <label className='required fw-bold fs-6 mb-2'>Is Spoil</label>
            </div>
            <div className='d-flex justify-content-end'>
              <div className='form-check form-check-custom form-check-solid form-check-success form-switch'>
                <div
                  className='form-check form-check-custom form-check-solid form-switch
                    mb-2'
                >
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='publish'
                    checked={customAttrs.is_spoil}
                    onChange={() => {
                      setCustomAttrs({...customAttrs, is_spoil: !customAttrs.is_spoil})
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='separator separator-dashed my-6'></div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {CommentEditModalForm}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  data: any
}

const CommentReportAdminCommentCell: FC<Props> = ({data}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span style={{maxWidth: '500px'}}>{data.admin_comment}</span>
      </div>
    </div>
  )
}

export {CommentReportAdminCommentCell}

import axios from 'axios'
import {processApiGetAllRolesResponse, processApiGetRoleByIDResponse} from "./Helpers";
import qs from 'qs';

import {roleUrls} from "../../../../common/core/ApiURLData"
import {Role} from "../../core/_models";
import {ID} from "../../../../../../_metronic/helpers";
import {ApiResult} from "../../../../common/core/_models";
const getAllRole = async (): Promise<Role[]> => {
    const {sort = 'id', order = 'desc'} = {};
    let uri = qs.stringify({
        orderBy: `${sort}:${order}`,
    }, {
        skipNulls: true,
    })
    const apiResponse = await axios.get(`${roleUrls.GET_ALL}&${uri}`).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    const apiResult = processApiGetAllRolesResponse(apiResponse);
    return (apiResult.success && apiResult.result) ? apiResult.result : [];
}
const getRoleById = async (id: ID): Promise<Role> => {
    let uri = qs.stringify({
        id,
    }, {
        skipNulls: true,
    })
    const apiResponse = await axios.get(`${roleUrls.GET_BY_ID}&${uri}`).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    const apiResult = processApiGetRoleByIDResponse(apiResponse);
    return (apiResult.success && apiResult.result) ? apiResult.result : {};
}
const addRole = async (role: Role, permissions: number[]): Promise<ApiResult<Role>> => {
    const apiResponse = await axios.post(`${roleUrls.ADD_ROLE}`, {...role, permissions}).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiGetRoleByIDResponse(apiResponse);
}

const updateRole = async (role: Role, permissions: number[]): Promise<ApiResult<Role>> => {
    const apiResponse = await axios.put(`${roleUrls.UPDATE_ROLE}`, {...role, permissions}).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiGetRoleByIDResponse(apiResponse);
}

const deleteRoleById = async (id: number): Promise<boolean> => {
    const apiResponse = await axios.delete(`${roleUrls.DELETE_BY_ID}`, {data: {id}}).then(function (response) {
        return response.data;
    }).catch(function (error) {
        let response = error.response;
        if (response) {
            return response.data;
        }
        return {};
    })
    return processApiGetRoleByIDResponse(apiResponse).success;
}

export {getAllRole, getRoleById, addRole, updateRole, deleteRoleById}

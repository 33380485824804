/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
// import {User} from '../../core/_models'

type Props = {
  data: any
}

const CommentReportInfoCell: FC<Props> = ({data}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{data.Comment.manga.title}</span>
      </div>
    </div>
  )
}

export {CommentReportInfoCell}

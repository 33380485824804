import {useListView} from '../../core/ListViewProvider'
import {CommentReportListToolbar} from './CommentReportsListToolbar'
import {CommentReportListGrouping} from './CommentReportsListGrouping'

const CommentReportsListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <div></div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/*  /!* begin::Group actions *!/*/}
        {selected.length > 0 ? <CommentReportListGrouping /> : <CommentReportListToolbar />}
        {/*  /!* end::Group actions *!/*/}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CommentReportsListHeader}

import {AdminModel} from "./_models";
import {ApiResponse, ApiResult} from "../../../common/core/_models";
import {Role} from "../../role/core/_models";

const processApiGetListAdminsResponse = (data: ApiResponse<{rows: AdminModel[], count: number}>): ApiResult<{rows: AdminModel[], count: number}> => {
    let result = {
        success: false,
        message: 'Opp. Something went wrong!',
    };
    return {...result, ...data};
}


const processApiCreateAdminResponse = (data: ApiResponse<AdminModel>): ApiResult<AdminModel> => {
    let result = {
        success: false,
        message: 'Opp. Something went wrong!',
    };
    return {...result, ...data};
}


const processApiDeleteAdminResponse = (data: ApiResponse<AdminModel>): ApiResult<AdminModel> => {
    let result = {
        success: false,
        message: 'Opp. Something went wrong!',
    };
    return {...result, ...data};
}

export {processApiGetListAdminsResponse, processApiCreateAdminResponse, processApiDeleteAdminResponse}

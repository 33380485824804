// @ts-nocheck
import {Column} from 'react-table'
import {TargetResourceCell} from './TargetResourceCell'
import {UserActionsCell} from './UserActionsCell'
// import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {AdminModel} from '../../../core/_models'
import {UserRoleCell} from './UserRoleCell'
import {TimeCell} from '../../../../../common/components/list/columns/TimeCell'

const usersColumns: ReadonlyArray<Column<AdminModel>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Author' className='min-w-125px' />
    ),
    id: 'admin:name',
    Cell: ({...props}) => (
      <TargetResourceCell targetResource={props.data[props.row.index].admin.name} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Target resource' className='min-w-125px' />
    ),
    id: 'target_type',
    Cell: ({...props}) => (
      <TargetResourceCell targetResource={props.data[props.row.index].target_type} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='min-w-125px' />
    ),
    accessor: 'action_type',
    Cell: ({...props}) => (
      <TargetResourceCell targetResource={props.data[props.row.index].action_type} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Occurred at' className='min-w-125px' />
    ),
    accessor: 'created_at',
    Cell: ({...props}) => <TimeCell time={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Detail' className='min-w-125px' />
    ),
    accessor: 'detail',
    Cell: ({...props}) => (
      <TargetResourceCell targetResource={props.data[props.row.index].detail} />
    ),
  },
]

export {usersColumns}

import {useQuery} from 'react-query'
import {ComemntReportEditModalForm} from './CommentReportEditModalForm'
import {useListView} from '../core/ListViewProvider'
import { getCommentReportByID } from '../core/_requests'

const CommentReportEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {
    isLoading,
    data: commentReport,
    error,
  } = useQuery(
    `comment-report-item-${itemIdForUpdate}`,
    () => {
      // return 1
      return getCommentReportByID(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!isLoading && !error && commentReport) {
    return <ComemntReportEditModalForm isUserLoading={isLoading} commentReport={commentReport} />
  }

  return null
}

export {CommentReportEditModalFormWrapper}

import axios from 'axios'
import {mangaUrls, userUrls} from '../../../modules/common/core/ApiURLData'
import qs from 'qs'

const countRecentNewUser = async (hours: number): Promise<any> => {
  let uri = qs.stringify(
    {
      period: hours,
    },
    {
      skipNulls: true,
    }
  )

  const apiResponse = await axios
    .get(`${userUrls.COUNT_RECENT_NEW}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  return apiResponse
}

const countRecentUpdatedManga = async (hours: number): Promise<any> => {
  let uri = qs.stringify(
    {
      period: hours,
    },
    {
      skipNulls: true,
    }
  )

  const apiResponse = await axios
    .get(`${mangaUrls.COUNT_RECENT_UPDATE}&${uri}`)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      let response = error.response
      if (response) {
        return response.data
      }
      return {}
    })
  return apiResponse
}

export {countRecentNewUser, countRecentUpdatedManga}

// @ts-nocheck
import {Column} from 'react-table'
import {CommentReportInfoCell} from './CommentReportInfoCell'
import {CommentReportSelectionCell} from './CommentReportSelectionCell'
import {CommentReportCustomHeader} from './CommentReportCustomHeader'
import {CommentReportSelectionHeader} from './CommentReportSelectionHeader'
import {CommentReportContentCell} from './CommentReportContentCell'
import {CommentReportTypeCell} from './CommentReportTypeCell'
import {CommentReportStatusCell} from './CommentReportStatusCell'
import {CommentReportAdminCommentCell} from './CommentReportAdCommentCell'
import {CommentReportAdminCell} from './CommentReportAdCell'
import {ActionsCell} from './ActionsCell'

const CommentReportColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => <CommentReportSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <CommentReportSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='ID' className='min-w-20px' />
    ),
    accessor: 'id',
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='Manga' className='min-w-50px' />
    ),
    id: 'Comment:manga:title',
    Cell: ({...props}) => <CommentReportInfoCell data={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='Comment' className='min-w-50px' />
    ),
    id: 'Comment:content',
    Cell: ({...props}) => <CommentReportContentCell data={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='Type' className='min-w-50px' />
    ),
    id: 'type',
    Cell: ({...props}) => <CommentReportTypeCell data={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='Status' className='min-w-50px' />
    ),
    id: 'status',
    Cell: ({...props}) => <CommentReportStatusCell data={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader tableProps={props} title='Admin Comment' className='min-w-50px' />
    ),
    id: 'admin_comment',
    Cell: ({...props}) => <CommentReportAdminCommentCell data={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommentReportCustomHeader
        tableProps={props}
        title='Admin'
        className='min-w-50px max-w-150px'
      />
    ),
    id: 'Admin:name',
    Cell: ({...props}) => <CommentReportAdminCell data={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <CommentReportCustomHeader
        tableProps={props}
        title='Actions'
        className='min-w-50px max-w-150px'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell data={props.data[props.row.index]} />,
  },
]

export {CommentReportColumns}

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {CommentReportsTable} from './table/CommentReportsTable'
import {CommentReportEditModal} from './comment-report-edit-modal/CommentReportEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {CommentReportsListHeader} from './components/header/CommentReportsListHeader'
import {ConfirmDelete} from '../../../common/components/modals/ConfirmDelete'
import {deleteById} from './core/_requests'

const CommentReportsList = () => {
  const {itemIdForUpdate, itemIdForDelete, setItemIdForDelete} = useListView()
  const {refetch} = useQueryResponse()
  const deleteItem = async () => {
    if (itemIdForDelete) {
      const deleted = await deleteById(itemIdForDelete)
      if (deleted) {
        refetch()
      }
    }
  }
  return (
    <>
      <KTCard>
        <CommentReportsListHeader />
        <CommentReportsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CommentReportEditModal />}
      {itemIdForDelete !== undefined && (
        <ConfirmDelete setItemIdForUpdate={setItemIdForDelete} deleteFunction={deleteItem} />
      )}
    </>
  )
}

const CommentReportsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CommentReportsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CommentReportsListWrapper}

import {FC} from 'react'
import moment from 'moment';

type Props = {
    time: Date | string
}

const TimeCell: FC<Props> = ({ time }) => {
  return (
    <>{moment(time).format('DD/MM/YYYY HH:mm:ss')}</>
  )
}

export {TimeCell}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  targetResource: string
}

const TargetResourceCell: FC<Props> = ({targetResource}) => {
  return <>{targetResource}</>
}

export {TargetResourceCell}

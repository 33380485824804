/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
  className: string
  count: string
  total: string
  description1: string
  description2: string
}

const CardsType1 = ({className, count, total, description1, description2}: Props) => (
  <div
    className={`card card-flush ${className}`}
    style={{
      height: 'auto !important',
    }}
//     style='
//   height: auto !important;
// '
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{count}</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description1}</span>
        </div>
        <div className='card-title d-flex flex-column mt-10'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{total}</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description2}</span>
        </div>
      </div>
    </div>
  </div>
)
export {CardsType1}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget3,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
} from '../../../_metronic/partials/widgets'
import {CardsType1} from './Components/CardType1'
import {useQuery} from 'react-query'
import {countRecentNewUser, countRecentUpdatedManga} from './core/_request'

const DashboardPage: FC = () => {
  const {
    isLoading: userLoading,
    data: userData,
    error: userError,
  } = useQuery(
    `count-recent-new-user`,
    () => {
      return countRecentNewUser(24)
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  const {
    isLoading: mangaLoading,
    data: mangaData,
    error: mangaError,
  } = useQuery(
    `count-recent-updated-manga`,
    () => {
      return countRecentUpdatedManga(24)
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        // setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsType1
            className=' mb-5 mb-xl-10'
            count={`+ ${userLoading && !userError ? 0 : userData?.result?.count}`}
            total={userLoading && !userError ? 0 : userData?.result?.total}
            description1='New users in 24 hours'
            description2='Total users'
          />
          
          {/* <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          /> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsType1
            className=' mb-5 mb-xl-10'
            count={`${mangaLoading && !mangaError ? 0 : mangaData?.result?.count}`}
            total={mangaLoading && !mangaError ? 0 : mangaData?.result?.total}
            description1='Mangas updated in 24 hours'
            description2='Total mangas'
          />
          {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' /> */}
          {/* <ListsWidget26 className='h-lg-50' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          {/* <ListsWidget3 className='card-xxl-stretch mb-xl-3' /> */}
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

import {AuthTokens} from './_models'
import {ApiResponse, ApiResult} from "../../common/core/_models";
import {AdminModel} from "../../admin-management/admin/core/_models";
import {refreshToken} from "./_requests";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthTokens | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthTokens = JSON.parse(lsValue) as AuthTokens
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthTokens) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.access.token) {
        config.headers.Authorization = `Bearer ${auth.access.token}`
      }
      return config
    },
    async (err: any) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
      function (response: any) {
        return response;
      },
      async function (err: any) {
        const { response } = err;
        if (response && response.status) {
          const { config } = err;
          let tried = false;
          if (config.headers.tried && typeof config.headers.tried !== 'undefined'){
            tried = true;
          }
          if (response.status === 401 && !tried) {
            const auth = getAuth()
            if (auth && auth.refresh.token) {
              const refreshTokenResponse = await refreshToken(auth.refresh.token);
              if (refreshTokenResponse.success && refreshTokenResponse.result){
                const newTokens = refreshTokenResponse.result.tokens;
                setAuth(newTokens);
                const newAccessToken = newTokens.access.token;
                config.headers.Authorization =  `Bearer ${newAccessToken}`;
                config.headers.tried = 1;
                return axios(config);
              }
            }
          }
        }
        return Promise.reject(err);
      }
  );
}

const processApiLoginResponse = (data: ApiResponse<{ admin: AdminModel, tokens: AuthTokens }>): ApiResult<{ admin: AdminModel, tokens: AuthTokens }> => {
  let result = {
    success: false,
    message: 'Opp. Something went wrong!',
  };
  return {...result, ...data};
}

const processApiGetUserInfoResponse = (data: ApiResponse<{ admin: AdminModel }>): ApiResult<{ admin: AdminModel }> => {
  let result = {
    success: false,
    message: 'Opp. Something went wrong!',
  };
  return {...result, ...data};
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, processApiLoginResponse, processApiGetUserInfoResponse}
